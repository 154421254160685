<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <!-- 注册的顶部 -->
    <div class="registerTop">
      <div class="typeArea">
        <!-- logo -->
        <h1 class="logo">
          <router-link :to="{ path: '/' }">
            <img :src="$localPicture+'logo/logo_black.png'" alt="logo">
          </router-link>
        </h1>
        <!-- 欢迎登录 -->
        <p>平台协议</p>
      </div>
    </div>
    <div class="compact-html" v-html="compactHtml" />
  </div>
</template>

<script>
import { compactServiceGetContent } from '@/http/agentApi/login'
export default {
  data() {
    return {
      compactHtml: ''
    }
  },
  created() {
    this.getCompactServiceGetContent()
  },
  methods: {
    getCompactServiceGetContent() {
      compactServiceGetContent({
        compactId: '6ec1453f73994eea8a1d00205ad2ecae'
      }, res => {
        this.compactHtml = res.data.compactContent
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .registerTop {
    z-index: 36;
    height: 8.3vh;
    background-color: #fff;
    border-bottom: 1px solid #E3E3E3;

    .typeArea {
      display: flex;
      align-items: center;
      height: 100%;
      width: 1200px;
      margin: auto;
      color: #747774;
      font-size: 18px;

      .logo {
        // width: 229px;
        height: 5.6vh;
        margin-right: 43px;
        z-index: 4545;
        a,
        img {
          height: 100%;
          width: 100%;
          display: inline-block;
        }
      }
    }
  }
  .compact-html {
    padding: 34px 50px;
  }
</style>
